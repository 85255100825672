<template>
    <b-row class="mt-4 signatories">
        <b-col sm="4">
            <b-row class="px-2">
                <b-col sm="12" class="form-sign-value">
                    &nbsp;{{ deployedByUser }}&nbsp;
                </b-col>
                <b-col sm="12" class="form-sign-label">
                    Dispatcher
                </b-col>
            </b-row>
        </b-col>

        <b-col sm="4">
            <b-row class="px-2">
                <b-col sm="12" class="form-sign-value">
                    &nbsp;{{selDispatch.driver ? selDispatch.driver.name : '-'}}&nbsp;
                </b-col>
                <b-col sm="12" class="form-sign-label">
                    Driver
                </b-col>
            </b-row>
        </b-col>

        <b-col sm="4">
            <b-row class="px-2">
                <b-col sm="12" class="form-sign-value">
                    &nbsp;{{ receivedByUser }}&nbsp;
                </b-col>
                <b-col sm="12" class="form-sign-label">
                    Receiver
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'dispatch-summary-assets',
    props: {
        selDispatch: {
            type: Object,
            required: true,
        },
        deployedByUser: {
            type: String,
            required: true,
        },
        receivedByUser: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>
.form-sign-value {
    font-size: .8rem;
    text-align: center;
    line-height: 1rem;
    color: #484554;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #4A4A4A;
}

.form-sign-label {
    font-size: .7rem;
    text-align: center;
    color: #484554;
}

.signatories {
    margin-bottom: 3em !important;
    margin-top: 1em !important;
}

</style>