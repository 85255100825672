<template>
	<b-row class="px-2 mb-1">
		<b-col sm="6" class="border border-light border-1 p-2">
			<div class="section-header">Source</div>
			<!-- Company -->
			<div class="form-field">Company: <span class="form-value-uppercase">{{ sourceCompanyName }}</span></div>
			<!-- Warehouse -->
			<div class="form-field">Warehouse: <span class="form-value-uppercase">{{ sourceLocationName }}</span></div>
			<!-- Address -->
			<div class="form-field">Address: <span class="form-value-uppercase">{{ sourceLocation &&
				sourceLocation.address ? sourceLocation.address : '-' }}</span></div>
			<!-- Date Deployed -->
			<div class="form-field">Date Dispatched: <span class="form-value-uppercase">{{ selDispatch.dateDeployed ?
				showFormattedDate(selDispatch.dateDeployed) : '-' }}</span></div>
		</b-col>

		<b-col sm="6" class="border border-light border-1 p-2">
			<div class="section-header">Destination</div>
			<!-- Company -->
			<div class="form-field">Company: <span class="form-value-uppercase">{{ destinationCompanyName }}</span>
			</div>
			<!-- Warehouse -->
			<div class="form-field">Warehouse: <span class="form-value-uppercase">{{ destinationLocationName }}</span>
			</div>
			<!-- Address -->
			<div class="form-field">Address: <span class="form-value-uppercase">{{ destinationLocation &&
				destinationLocation.address ? destinationLocation.address : "-" }}</span></div>
			<!-- Date Received -->
			<div class="form-field">Date Received: <span class="form-value-uppercase">{{ selDispatch.dateReceived ?
				showFormattedDate(selDispatch.dateReceived) : '-' }}</span></div>
		</b-col>
	</b-row>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

export default {
	name: 'dispatch-summary-source-and-destination',
	props: {
		selDispatch: {
			type: Object,
			required: true,
		},
		sourceLocation: {
			type: Object,
			required: true,
		},
		destinationLocation: {
			type: Object,
			required: true,
		}
	},
	computed: {
		sourceCompanyName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let source = dispatch.source ? dispatch.source : {};
			return source && source.company && source.company.length > 0 ? source.company : '-'
		},
		sourceLocationName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let source = dispatch.source ? dispatch.source : {};
			return source && source.storageLocation && source.storageLocation.length > 0 ? source.storageLocation : '-'
		},
		destinationCompanyName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let destination = dispatch.destination ? dispatch.destination : {};
			return destination && destination.company && destination.company.length > 0 ? destination.company : '-'
		},
		destinationLocationName() {
			let dispatch = this.selDispatch ? this.selDispatch : {};
			let destination = dispatch.destination ? dispatch.destination : {};
			return destination && destination.storageLocation && destination.storageLocation.length > 0 ? destination.storageLocation : '-'
		},
	},
	methods: {
		showFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	}
}
</script>

<style scoped>
.section-header {
	text-transform: uppercase;
	color: #122C91;
	font-size: .7rem;
	font-style: normal;
	font-weight: bold;
	line-height: normal;
}

.form-field {
	font-size: .7rem;
	text-align: left;
	text-transform: uppercase;
	color: #4A4A4A;
	font-weight: 400;
	line-height: normal;
}

.form-value-uppercase {
	font-size: .7rem;
	text-align: left;
	text-transform: uppercase;
	color: #484554;
	font-weight: 700;
	line-height: normal;
}
</style>