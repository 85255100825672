<template>
    <b-row>
        <b-col class="footer-col text-right" sm="12">
            <span class="copy-owner" v-if="copyOwner.length > 0">{{ copyOwner + ' Copy' }}</span>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'dispatch-summary-footer',
    props: {
        copyOwner: {
            type: String,
            required: true
        }
    },
}
</script>

<style scoped>
.footer-col {
    padding-top: 0.7em;
    font-size: small;
}

.copy-owner {
	font-size: .7rem;
	text-align: right;
	margin-top: -3rem;
	display: block;
    text-transform: uppercase;
}
</style>